// Typography
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Roboto:wght@400;500;700;900&display=swap');
@import 'shared';

h1 {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 49px;
  color: $blue;
  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 33px;
  }
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: $black;
  font-family: 'Roboto';
}

button {
  font-family: 'Roboto';
}
