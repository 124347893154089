// Wrapper width
$global-wrapper-width: 1344px;

// Font Family
$font-family: 'Roboto', sans-serif;

// Images path
$images-path: '/assets/images/';

// Colors
$white: #ffffff;
$black: #000000;
$blue: #143c6d;
$blue-light: #92dadd;
$red: #ff0000;
$white-alpha: rgba($white, 0.93);
