// Global icons
*[class*='icon-'] {
  &.icon-instagram {
    @include icon('instagram.svg');
  }
  &.icon-facebook {
    @include icon('facebook.svg');
  }
  &.icon-envelop {
    @include icon('envelop.svg');
  }
}
